/* Global Styles */
body {
  background: radial-gradient(circle at center, #020202, #000000 80%);
  font-family: 'Orbitron', sans-serif;
  color: #ffffff; /* Default text color set to white */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Smooth Scroll Bar */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #1a1a1a;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #0074D9, #ff6600);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #ff6600, #0074D9);
}

/* Header */
.header {
  width: 100%;
  background: linear-gradient(90deg, #1a1a1a, #0d0d0d);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #FFAA0099;
  box-shadow: 0 0 15px rgba(255, 170, 0, 0.2);
}

.logo {
  height: 80px;
  width: 120px;  /* Mantiene le proporzioni */
  background-image: url('../public/logong.png'); /* Percorso del logo */
  background-size: contain; /* Assicura che l'immagine sia contenuta nel box */
  background-repeat: no-repeat; /* Evita ripetizioni */
  background-position: center; /* Centra l'immagine */
  display: block; /* Assicura che si comporti come un elemento blocco */
  text-indent: -9999px; /* Nasconde il testo */
  overflow: hidden; /* Nasconde eventuali parti dell'immagine eccedenti */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-link {
  text-decoration: none;
  color: #ffffff; /* Link color */
  padding: 10px 20px;
  border: 1px solid transparent; /* Imposta un bordo di base trasparente */
  border-image: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet) 1;
  border-radius: 5px;
  transition: all 0.3s;
  text-shadow: 0 0 8px #0074D9;
}

.nav-link:hover {
  background-color: #0074D9;
  color: #000;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 500px;
  position: relative;
  overflow: hidden; /* Assicura che il video non trabocchi */
  background-color: #000; /* Colore di fallback */
}

.hero-content {
  flex: 1;
  padding: 20px;
  z-index: 2; /* Assicura che il contenuto sia sopra il video */
  color: #fff;
  text-align: left;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #0074D9;
  text-shadow: 0px 0px 15px #0074D9;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero-video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%; /* Copre metà dello schermo a destra */
  height: 100%;
  object-fit: cover; /* Adatta il video per coprire completamente lo spazio */
  z-index: 1; /* Dietro al contenuto */
  opacity: 0.8; /* Puoi regolare l'opacità */
}

/* Features Section */
.features {
  padding: 60px 20px;
  background: linear-gradient(180deg, #0a0a0a, #020202);
  color: #ffffff; /* Default text color */
  box-shadow: 0 0 20px rgba(255, 170, 0, 0.6);
}

.features h2 {
  color: #FFAA0099; /* Title color */
  text-shadow: 0px 0px 15px #FFAA0099;
}

.feature-card {
  background: linear-gradient(145deg, #111111, #222222);
  border: 1px solid transparent; /* Imposta un bordo di base trasparente */
  border-image: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet) 1;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(255, 170, 0, 0.6), inset 0px 2px 10px rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(255, 170, 0, 0.6), inset 0px 4px 20px rgba(0, 0, 0, 0.8);
}

.feature-card h3 {
  color: #FFAA0099; /* Title color */
}

.feature-card p {
  color: #ffffff; /* Body text color */
}

/* Meme Tokens Container */
.tokens-container {
  padding: 60px 20px;
  background: linear-gradient(180deg, #0f0f0f, #020202);
  color: #ffffff; /* Default text color */
}

.tokens-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #FFAA0099; /* Title color */
  text-shadow: 0px 0px 15px #FFAA0099;
}

.tokens-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.token-card {
  background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
  border: 1px solid transparent; /* Imposta un bordo di base trasparente */
  border-image: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet) 1;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(255, 170, 0, 0.6), inset 0px 2px 10px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden; /* Ensures content doesn’t spill out */
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Adds additional wrapping for older browsers */
}

.token-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(255, 170, 0, 0.6), inset 0px 4px 20px rgba(0, 0, 0, 0.8);
}

.tokens-page h3 {
  color: #FFAA0099; /* Title color */
  margin-bottom: 10px;
  text-overflow: ellipsis; /* Adds ellipsis for long titles */
  white-space: nowrap; /* Prevents wrapping for titles */
  overflow: hidden; /* Hides overflow content */
}


.token-card h3 {
  color: #FFAA0099; /* Title color */
  margin-bottom: 10px;
  text-overflow: ellipsis; /* Adds ellipsis for long titles */
  white-space: nowrap; /* Prevents wrapping for titles */
  overflow: hidden; /* Hides overflow content */
}

.token-card p {
  color: #ffffff; /* Body text color */
  margin: 5px 0;
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Ensures wrapping */
}


/* Footer */
.footer-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: radial-gradient(circle, #0d0d0d, #000000);
  border-top: 1px solid #0074D9;
  box-shadow: 0 -2px 10px rgba(255, 170, 0, 0.6);
}

.footer-text {
  color: #ffffff; /* Body text color */
  font-size: 0.8rem;
  text-shadow: 1px 1px 2px #000;
  margin-left: 20px;
}

.social-links img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s;
}

.social-links img:hover {
  transform: scale(1.1);
  filter: brightness(1.5);
}

/* Buttons */
.retro-button {
  padding: 15px 30px;
  border: none;
  background: linear-gradient(90deg, #FFAA0099, #ffaa55);
  color: #000;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.retro-button:hover {
  background: linear-gradient(90deg, #ffaa55, #FFAA0099);
  box-shadow: 0 0 15px rgba(255, 170, 85, 0.8);
}
